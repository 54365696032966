import { employeeinfo } from "../commonDynmicForms/employee_info";
import { FillableBy } from "./form_enums";
import { getSignatures } from "../commonDynmicForms/signature";
import { table_fields} from "../commonDynmicForms/table_fields"
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { constants } from "../constants";

export default {
    fillable_by : FillableBy.Both,
    title:
        "Spectrum Quantum ECMO Competency Checklist",
    sections: [
        // {...employeeinfo},
        {
            title: "General Knowledge & Training",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Instruction(s) for Use Availability</b><br/>1. Can locate and reference the manufacturer’s <b>Instructions for Use (IFU)</b>.<br/>2. Understands how to access digital/printed manuals and quick reference guides.",
                "<b>E-Learning Enrollment & Completion</b><br/>1. Completed manufacturer’s  <b>online training</b> and certification modules.<br/>2. Passed any required <b>knowledge assessments or quizzes</b>."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Equipment Familiarization & Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Hardware Components</b><br/>1. Can identify and explain <b>all key components of the Spectrum Quantum ECMO Machine</b>.<br/>2. Understands <b>power supply requirements, battery backup function, and cable management.</b>.<br/>3. Demonstrates proper assembly and disassembly of ECMO circuits and components.",
                "<b>Sterile Technologies</b><br/>1. Understands <b>the importance of maintaining a sterile field</b> during circuit setup and priming.<br/>2. Demonstrates correct handling of <b>sterile tubing, connectors, and oxygenators</b>.<br/>3. Verifies sterility and proper priming of<b>ECMO circuits</b> before use.",
                "<b>Gauge Bar/Diagnostics/Capture-Sync</b><br/>1. Correctly interprets <b>pressure, temperature, and flow readings</b> for ECMO.<br/>2. Verifies functionality of <b>real-time diagnostics and alarm logging</b>.<br/>3. Synchronizes <b>data capture with electronic ECMO records (if applicable)</b>.",
                "<b>System Settings</b><br/>1. Configures <b>patient-specific ECMO settings</b>, including <b>RPM, and flow rates</b>.<br/>2. Adjusts system parameters as per <b>neonatal, pediatric, or adult ECMO protocols</b>.<br/>3.Verifies settings before and during <b>ECMO initiation</b>.",
                "<b>Pump Manager (Centrifugal Pump Only)</b><br/>1. Operates <b>centrifugal pump system</b> effectively.<br/>2.Adjusts <b>RPM and flow settings</b> safely and efficiently.<br/> 3. Monitors <b>circuit integrity, pressure gradients, and oxygenator performance</b>.",
                "<b>Pump Interventions (Bubble Detection, Level, Pressure Monitoring)</b><br/>1. Demonstrates competency in <b>air bubble detection and intervention</b>.<br/>2. Correctly responds to <b>low-level alarms and adjusts as needed</b>.<br/>3. Monitors <b>pre-membrane and post-membrane pressures</b> to identify potential circuit issues.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Advanced Features & Safety Protocols",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Interlock Safeties and Alarm Management</b><br/>1. Understands <b>alarms related to ECMO flow, pressure, and air detection</b>.<br/>2. Configures and verifies alarm settings before ECMO initiation.<br/>3. Responds appropriately to alarms, pressure drops, or flow disruptions.",
                "<b>Smart Modes</b><br/>1. Engages and utilizes Smart Modes for automated adjustments.<br/>2. Explains how different Smart Modes impact ECMO flow and oxygenation.<br/>3. Adjusts settings based on patient-specific conditions (VV vs. VA ECMO).",
                "<b>VIPER and Toolbox Applications</b><br/>1. Accesses and navigates VIPER (Variable Input Patient Electronic Record).<br/>2. Uses <b>Toolbox Applications</b> for troubleshooting and optimizing performance.<br/>3. Logs and retrieves <b>ECMO-specific perfusion data</b> from the machine.",
                "<b>Device Connectivity</b><br/>1. Establishes <b>connectivity with external ECMO monitoring systems</b>.<br/>2. Integrates with <b>Electronic Medical Records (EMR) if applicable</b>.<br/>3. Troubleshoots <b>common connectivity issues.</b>",
                "<b>Administrator Mode (Superusers Only)</b><br/>1. Accesses <b>advanced system settings and configurations</b>.<br/>2. Performs <b>software updates and calibrations as needed.</b><br/>3. Assigns <b>user access levels and manages system security</b>.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Clinical Application & Performance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Trainee Observation</b><br/>1. Shadowed a trained <b>ECMO specialist or perfusionist</b> during machine setup and patient connection.<br/>2. Asked <b>questions and demonstrated understanding of key functions</b>.",
                "<b>Clinical Case(s) Performed</b><br/>1. Successfully <b>operated the Spectrum Quantum during live ECMO cases</b>.<br/>2. Adjusted parameters in response to <b>patient hemodynamic changes</b>.<br/>3. Worked effectively as part of the <b>multidisciplinary ECMO team</b>.",
                "<b>Emergency Procedures</b><br/>1. Responds effectively to <b>power failure and battery backup activation</b>.<br/>2. Demonstrates proper protocol for <b>pump failure and emergency hand cranking (if applicable)</b>.<br/>3. Handles <b>air embolism detection and response</b>.<br/>4. Implements contingency plans for <b>oxygenator failure</b>.<br/>5. Responds appropriately to <b>clot formation and hemolysis risk</b>.<br/>6. Manages <b>flow or oxygenation failure scenarios</b>.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both),
        },
    ],
}